import React, { Component } from 'react';
class Footer extends Component {

    constructor(props) {
     super(props)
    }

  render() {
    return (
      <div>
      <footer>
        <div className="container-fluid footer ">
        <div className="row">
        <div className="col-6">
        <p className="text-left m-0 p-0 "><span className="powered-color font-size-footer clr-default">Powered By:</span>
        <a className="   powered-color" href="https://www.experizer.com/" target="_blank">Experizer</a></p></div>
        <div className="col-6">
       <p className="text-right m-0 pt-1"><a href="https://studio.experizer.com/#/term  " className="powered-color font-size-footer" target="_blank"> Terms & Data Policy </a></p></div></div>
        </div>
        </footer>
    </div>
    );
  }
}

export default Footer;

