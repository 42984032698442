import React, { Component } from 'react';
import apiservice from '../../Services/apiservice';
import { Button } from 'react-bootstrap';
class LastComponent extends React.Component {
  data;
  constructor(props) {
    super(props);
    this.state = {
      editor: null,
      trending: null,
      viewed: null,
      published: null,
      categoryTypes: [],
    };
    this.api = new apiservice();
  }
  componentDidMount() {
    this.getData();
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;//scroll to top of the page
  }
  getData = () => {
    this.getAllTypeData();
    this.getTrendingData();
    this.getViewData();
    this.getPublishData();
    this.getEditorData();
  }
  getEditorData = () => {
    const url = "/experience/getEditorsChoice";
    this.api.getData(url).then(result => {
      this.editor = result.data.data;
      this.setState({ editor: null });
      this.setState({ trending: result.data.data });
      this.setState({ viewed: result.data.data });
      //  this.props.onClick("EDITORS CHOICE",this.editor[0].experienceId,this.editor);
      console.log("published", this.published);
    }).catch((error) => {
      console.log(error);
    });
  }
  getTrendingData = () => {
    const url = "/experience/getTrendingExperience";
    this.api.getData(url).then(result => {
      this.trending = result.data.data;
      console.log("trending", this.trending);
    }).catch((error) => {
      console.log(error);
    });
  }
  getPublishData = () => {
    const url = "/experience/getMostPublishExperience";
    this.api.getData(url).then(result => {
      this.published = result.data.data;
      this.setState({ published: result.data.data })
      console.log("published", this.published);
    }).catch((error) => {
      console.log(error);
    });
  }
  getViewData = () => {
    const url = "/experience/getMostViewedExperience";
    this.api.getData(url).then(result => {
      this.viewData = result.data.data;
      console.log("viewData", this.viewData);
    }).catch((error) => {
      console.log(error);
    });
  }
  onClick = (type, experienceId, data) => {
    // this.setState({[type]:null})
    // this.getTrendingData();
    // this.getViewData();
    this.showAllType();
    this.props.onClick(type, experienceId, data);
    console.log("type", type, "Experience Id", experienceId);
    switch (type) {
      case "TRENDING": this.setState({ trending: null });
        break;
      case "MOST VIEWED": this.setState({ viewed: null });
        break;
      case "RECENTLY PUBLISHED": this.setState({ published: null });
        break;
      case "EDITORS CHOICE": this.setState({ editor: null });
        break;
      default: this.setState({ [type]: null });
        break;
    }
    document.body.scrollTop = 800;
    document.documentElement.scrollTop = 800;//scroll to top of the page
  }
  showAllType = () => {
    this.setState({
      editor: this.editor,
      trending: this.trending,
      viewed: this.viewData,
      published: this.published
    });
    var allType = this.state.allTypeData;
    if (allType) {
      for (var i = 0; i < allType.length; i++) {
        if (allType[i].cate.length >= 5) {
          this.setState({
            [allType[i].categoryName]: allType[i]
          });
        }
      }
    }
  }
  getAllTypeData = () => {
    const url = "/experience/categoryWiseExperience";
    this.api.getData(url).then(result => {
      var allType = result.data.data;
      this.setState({ allTypeData: result.data.data });
      for (var i = 0; i < allType.length; i++) {
        if (allType[i].cate.length >= 5) {
          //  this.setState({[allType[i].categoryName]:null});
          this.setState({
            categoryTypes: this.state.categoryTypes.concat(allType[i].categoryName),
            [allType[i].categoryName]: allType[i]
          });
        }
      }
    }).catch((error) => {
      console.log(error);
    });
  }
  onClickShowMore = (id, type) => {
    localStorage.setItem("type", id);
    localStorage.setItem("typeName", type);
    window.location.hash = "#/public";
  }
  handleKeyDown = (e) => {
    e.preventDefault()
  }
  render() {
    var _self = this;
    return (
      <div className="LastComponent" onKeyDown={this.handleKeyDown} onKeyUp={this.handleKeyDown}>
        {this.state.editor ? (
          <section>
            <div className="container-fluid h5-div-style section-border-bottom pl-4 pr-4">
              <div>
                <h6 className="h6-trending-padding">EDITORS CHOICE</h6>
              </div>
              <div className="row">
                {this.state.editor.slice(0, 5).map(function (data, index) {
                  return (
                    <div className="col-sm-6 col-md-6 col-lg-3 col-xl" key={index}>
                      <div className="container-img border-to-cards clr-pointer" onClick={() => { _self.onClick("EDITORS CHOICE", data.ExperienceId, _self.state.editor) }}>
                        <img className="card-img-top-last" src={data.ThumbnailPath} />
                        <div className="overlay-trending">
                          <div className="text w-100">
                            <h6>{data.ExperienceName}</h6>
                            <p className="font-size">Grab your camera, snap multiple Panorama images and link them together to create a virtual tour.</p>
                          </div>
                        </div></div>
                      <h4 className="experience-name-trending pt-2">{data.ExperienceName}</h4>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        ) : null}
        {this.state.trending ? (
          <section>
            <div className="container-fluid h5-div-style section-border-bottom pl-4 pr-4">
              <div>
                <h6 className="h6-trending-padding">TRENDING</h6>
              </div>
              <div className="row">
                {this.state.trending && this.state.trending.slice(0, 5).map(function (trending, index) {
                  return (
                    <div className="col-sm-6 col-md-6 col-lg-3 col-xl clr-pointer" key={index} onClick={() => { _self.onClick("TRENDING", trending.ExperienceId, _self.state.trending) }}>
                      <div className="container-img border-to-cards " >
                        <img className="card-img-top-last" src={trending.ThumbnailPath} />
                        <div className="overlay-trending">
                          <div className="text w-100">
                            <h6>{trending.ExperienceName}</h6>
                          </div>
                        </div></div>
                      <h4 className="experience-name-trending pt-2">{trending.ExperienceName}</h4>
                    </div>
                  );
                })}
              </div>
              <div className="Textlink-SeeAll pl-4 pr-1 text-right">
              </div>
            </div>
          </section>
        ) : null}
        {this.state.published ? (
          <section>
            <div className="container-fluid h5-div-style section-border-bottom pl-4 pr-4">
              <div>
                <h6 className="h6-trending-padding">RECENTLY PUBLISHED</h6>
              </div>
              <div className="row">
                {this.state.published && this.state.published.slice(0, 5).map(function (data, index) {
                  return (
                    <div className="col-sm-6 col-md-6 col-lg-3 col-xl clr-pointer" key={index} onClick={() => { _self.onClick("RECENTLY PUBLISHED", data.ExperienceId, _self.state.published) }}>
                      <div className="container-img border-to-cards " >
                        <img className="card-img-top-last" src={data.ThumbnailPath} />
                        <div className="overlay-trending">
                          <div className="text w-100"> <h6>{data.ExperienceName}</h6>
                          </div>
                        </div></div>
                      <h4 className="experience-name-trending pt-2">{data.ExperienceName}</h4></div>
                  );
                })}
              </div>
              <div className="Textlink-SeeAll pl-4 pr-1 text-right">
                <Button variant="link" onClick={() => _self.onClickShowMore("public", "All Published Experiences")}>See More</Button>
                {/* <Link onClick={() => (localStorage.setItem("type", "public"),localStorage.setItem("typeName", "All Published Experiences"))}   to='/public'>See More</Link> */}
              </div>
            </div>
          </section>
        ) : null}
        {this.state.categoryTypes && this.state.categoryTypes.map(function (categoryType, index) {
          var categoryId;
          return (
            <section key={index}>
              {_self.state[categoryType] ?
                <div className="container-fluid h5-div-style section-border-bottom pl-4 pr-4">
                  <div>
                    <h6 className="h6-trending-padding">{categoryType}</h6>
                  </div>
                  <div className="row">
                    {_self.state[categoryType] && _self.state[categoryType].cate.slice(0, 5).map(function (viewData, index) {
                      categoryId = _self.state[categoryType].categoryId;
                      return (
                        <div className="col-sm-6 col-md-6 col-lg-3 col-xl clr-pointer" key={index} onClick={() => { _self.onClick(categoryType, viewData.ExperienceId, _self.state[categoryType].cate) }}>
                          <div className="container-img border-to-cards ">
                            <img className="card-img-top-last" src={viewData.ThumbnailPath} />
                            <div className="overlay-trending">
                              <div className="text w-100">     <h6>{viewData.ExperienceName}</h6>
                              </div>
                            </div></div>
                          <h4 className="experience-name-trending pt-2">{viewData.ExperienceName}</h4>
                        </div>
                      )
                    })}
                  </div>
                  <div className="Textlink-SeeAll pl-4 pr-1 text-right">
                    <Button variant="link" onClick={() => _self.onClickShowMore(categoryId, categoryType)} >See More</Button>
                  </div>
                </div>
                : null}
            </section>
          );
        })}
        {this.state.viewed ? (
          <section>
            <div className="container-fluid h5-div-style section-border-bottom pl-4 pr-4">
              <div>
                <h6 className="h6-trending-padding">MOST VIEWED</h6>
              </div>
              <div className="row">
                {this.state.viewed && this.state.viewed.slice(0, 5).map(function (viewData, index) {
                  return (
                    <div className="col-12 col-sm-6 col-md-6 col-lg col-xl clr-pointer" key={index} onClick={() => { _self.onClick("MOST VIEWED", viewData.ExperienceId, _self.state.viewed) }}>
                      <div className="container-img border-to-cards ">
                        <img className="card-img-top-last" src={viewData.ThumbnailPath} />
                        <div className="overlay-trending">
                          <div className="text w-100">     <h6>{viewData.ExperienceName}</h6>
                          </div>
                        </div></div>
                      <h4 className="experience-name-trending pt-2">{viewData.ExperienceName}</h4>
                    </div>
                  );
                })}
              </div>
              <div className="Textlink-SeeAll pl-4 pr-1 text-right">
              </div>
            </div>
          </section>
        ) : null}
      </div>
    )
  }
}
export default LastComponent;
