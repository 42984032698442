import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'font-awesome/css/font-awesome.min.css';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Switch, HashRouter as Router } from 'react-router-dom'
import LinkPage from './Components/linkPage/linkPage';

import createHashHistory from 'history/createHashHistory';
import Footer from './Components/Footer/Footer';
export const history = createHashHistory();

const routing = (
  <div id="page-container" >
    <div id="content-wrap">
      <Router history={history}>
        <Switch>

          <Route exact path="/" component={App} />
          <Route exact path="/public" component={LinkPage} />
        </Switch>
      </Router>
    </div>
    <div id="footer">
      <Footer></Footer>
    </div>
  </div>
)

ReactDOM.render(routing, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();