import React, { Component } from 'react';
import apiservice from '../../Services/apiservice';
import Avatar from '../../assests/images/avatar.png';
import { Form, InputGroup, FormControl, Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Notifications, { notify } from 'react-notify-toast';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import experizerBlackLogo from '../../assests/images/experizerme2.png';
import embedIcon from '../../assests/images/embed_96.png';
import clap_golden from '../../assests/images/clap2_golden_96.png'
import clap2 from '../../assests/images/clap2_96.png';
import urlIcon from '../../assests/images/link_96.png';
import eyeImg from '../../assests/images/eye.png';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  EmailIcon,
} from 'react-share';
const title = 'Check out this Experizer room.';
const reportOptions = ["Sexual content", "Violent or repulsive content", "Hateful or abusive content", "Harmful dangerous acts", "Promotes terrorism", "Child abuse", "Spam or misleading", "Infringes my rights", "Other"];
class LinkPage extends Component {
  like = false;
  type;
  otherTypeReport = false;
  constructor(props, context) {
    super(props, context);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      showReport: false,
      show: false,
      getAll: null,
      showShare: false,
      iframeData: null,
      loading: true,
      reportData: "Sexual content",
      reportFlag: false,
      clapFlag: false,
    };
    this.api = new apiservice();
    this.baseurl = 'https://studio.experizer.com';                                                        
  }
  componentDidMount() {
    this.type = localStorage.getItem("type");
    this.getData(this.type);
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;//scroll to top of the page
  }
  handleClose() {
    this.like = false;
    this.setState({
      show: false,
      showReport: false,
      showShare: false
    });
  }
  handleShow = (id, pid) => {
    this.setState({ clapFlag: false });
    const url = "/experience/reportedExp/" + id;
    this.api.getData(url).then(result => {
      var res = result.data.status;
      this.setState({ reportFlag: res });
      console.log("reportflag", this.state.reportFlag);
    }).catch((error) => {
      console.log(error);
    });
    const url1 = "/experience/getExpDataByRoomMappingId/" + pid;
    this.api.getData(url1).then(result => {
      var res = result.data.data[0];
      res.experienceName = (res.ExperienceName).split(' ').join('-');
      res.url = this.baseurl + '/act/' + res.ExperienceFolderPath + '/?' + res.publishMapId + '--0--0--0/' + res.experienceName;
      res.embed = '<iframe src="' + res.url + '" allowFullScreen={true} frameborder="0" scrolling="no" allow="vr,gyroscope,accelerometer" allowfullscreen="yes"  allowvr="yes"></iframe>';
      this.setState({ iframeData: res });
      console.log("Iframe Data", res);
    }).catch((error) => {
      console.log(error);
    });
    this.setState({ show: true });
  }
  searchData = (searhString) => {
    this.props.history.push("/public?search=" + searhString);
    this.setState({ loading: true });
  
    console.log("In linkpage", searhString);
    if (this.type == "public") {
      const url = "/experience/getSearchPromotedPublish?search=" + searhString;
      this.api.getData(url).then(result => {
        setTimeout(() => {
          this.setState({ loading: false });
        }, 2000);
        this.setState({ getAll: result.data.data })
      }).catch((error) => {
        console.log(error);
      });
    }
    else {
      const url = "/experience/searchByCategory/" + this.type + "?search=" + searhString;
      this.api.getData(url).then(result => {
        this.published = result.data.data;
        this.setState({ getAll: result.data.data })
        console.log("search Result", this.published);
        setTimeout(() => {
          this.setState({ loading: false });
        }, 2000);
      }).catch((error) => {
        console.log(error);
      });
    }
  }
  onLikeClick = (id) => {
    this.state.iframeData.likeCount += 1;
    var sendData = {
      "promotedId": id
    }
    const url = "/experience/likeCountUpdate";
    this.api.putData(url, sendData).then(result => {
      this.setState({ clapFlag: true });
    }).catch((error) => {
      console.log(error);
    });
    console.log(this.like);
    this.forceUpdate();
  }
  handleChange = (e) => {
    this.setState({ value: e.target.value });
  }
  handleShowUrl = () => {
    this.setState({ showURL: true });
  }
  handleSubmit = (e) => {
    console.log("e.target.value");
    e.preventDefault();//stops adding quetion mark in url
    this.searchData(this.state.value);
  }
  embedTagClick = () => {
    console.log(this.state.iframeData);
    var sendData = {
      "promotedId": this.state.iframeData.promotedId
    }
    const url = "/experience/EmbedCount";
    this.api.putData(url, sendData).then(result => {
      this.state.iframeData.Embedcount += 1;
      this.forceUpdate();
      notify.show('Embed Copied!', 'success', 4000);
    }).catch((error) => {
      console.log(error);
    });
  }
  urlTagClick = () => {
    notify.show('Link Copied!', 'success', 4000);
  }
  getData = (type) => {
    if (type == "public") {
      const url = "/experience/getAllPromotedPublish";
      this.api.getData(url).then(result => {
        this.published = result.data.data;
        this.setState({ getAll: result.data.data })
        console.log("published", this.published);
      }).catch((error) => {
        console.log(error);
      });
    }
    else {
      var data = {
        "id": type
      };
      const url = "/experience/categoryIdWiseExperience/" + type;
      this.api.getData(url, data).then(result => {
        this.setState({ getAll: result.data.data })
      }).catch((error) => {
        console.log(error);
      });
    }
  }
  handleShowShare = () => {
    this.setState({ showShare: !this.state.showShare });
  }
  onClickReport = () => {
    this.setState({ showReport: (!this.state.showReport), reportShow: true });
    this.otherTypeReport = false;
  }
  submitReport = (e) => {
    e.preventDefault();
    var expId = this.state.iframeData.ExperienceId;
    var expName = this.state.iframeData.ExperienceName
    console.log("Experience Id", expId);
    var sendData = {
      "reportText": this.state.reportData,
      "experienceId": expId,
      "experienceName": expName,
    }
    const url = "/experience/report";
    notify.show('Experience Reported', 'success', 4000);
    this.api.postData(url, sendData).then(result => {
      this.setState({ showReport: false, reportFlag: true });
      this.otherTypeReport = false;
    }).catch((error) => {
      console.log(error);
    });
    this.setState({ show: false, reportShow: false, selectedOption: null, reportData: "Sexual content" });
  }
  reportChange = (e) => {
    this.setState({ reportData: e.target.value });
  }
  handleOptionChange = (e) => {
    if (e.target.value == "Other") {
      this.otherTypeReport = true;
      this.setState({ reportData: e.target.null });
    }
    else {
      this.otherTypeReport = false;
      this.setState({ reportData: e.target.value });
    }
  }
  render() {
    let styles = {
      borderRadius: '50%',
      zIndex: '100px'
    };
    var marginLeft = {
      marginLeft: '-42px',
      marginTop: '1px'
    };
    var marginTopStyle = {
      marginTop: '-4px'
    };
    var _self = this;
    var shown = {
      display: this.state.loading ? "block" : "none"
    };
    var playTooltip = {
      marginTop: '80px'
    };
    var marginTopView = {
      marginTop: '-2px'
    };
    var socialMedia = {
      paddingRight: '30px'
    };
    return (
      <div >
        <div className="LinkPage" >
          <div className="loader" style={shown}> </div>
          <div className="container-fluid main-div pl-4 pr-4 fixed-top">
            <div className="row">
              <div className="col-2 ">
                <img className="logoimg" src={experizerBlackLogo} alt="experizer_logo" /></div>
              <div className="col-10 text-right">
                <a
                  href="https://studio.experizer.com/#/login" target="_blank">
                  <button type="submit" className="btn btn-info  btn btn-primary  padding-left-right">
                    Try Experizer
                      </button>
                </a>
              </div></div>
          </div>
          <div className="container-fluid pt-6 margin-back-button">
            <div className="row">
              <div className="pl-4">
                <Link to='/' ><button type="button" className="btn Shadow " style={styles} ><i className="fa fa-arrow-left"></i></button></Link></div>
              <div className="col-lg-6 col-xl-6 col-md-6 col-sm-6  m-0 pl-4">
                <h4 className="category-padding">{localStorage.getItem("typeName")}</h4>
              </div>
              <div className="col-lg-5 col-xl-5 col-md-12 col-sm-12 margin-left-search">
                <Form style={marginTopStyle} onSubmit={this.handleSubmit} >
                  <Form.Group className="mb-0 " controlId="validationCustomUsername">
                    <InputGroup>
                      <Form.Control
                        type="search"
                        className="input-box Shadow1"
                        placeholder="Search Experience"
                        aria-describedby="inputGroupPrepend"
                        value={this.state.value}
                        autoComplete="off"
                        onChange={this.handleChange}
                      />
                      <InputGroup.Append>
                        <Button className="btn btn-info Shadow1" variant="primary" type="submit" >Search </Button>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <section>
              <div className="container-fluid h5-div-style pl-4 pr-4">
                {this.state.getAll ?
                  <div className="row">
                    {this.state.getAll && this.state.getAll.map(function (data, index) {
                      return (
                        <div className=" col-sm-12 col-md-6 col-lg-3 col-xl-3" key={index} >
                          <div className="container-img border-to-cards clr-pointer" onClick={() => _self.handleShow(data.ExperienceId, data.promotedId)}>
                            <img className="card-img-top-last" src={data.ThumbnailPath} />
                            <div className="overlay-trending">
                              <div className="text w-100 pt-3"> <h6>{data.ExperienceName}</h6>
                              </div>
                            </div></div>
                          <h4 className="experience-name-trending pt-2">{data.ExperienceName}</h4>
                        </div>
                      );
                    })}
                  </div>
                  :
                  <div className="row justify-content-md-center">
                    <div className="my-5 " >
                      <h4>No data found</h4>
                    </div>
                  </div>}
              </div>
            </section>
          </div>
          <>
            {this.state.iframeData ?
              <Modal show={this.state.show} onHide={this.handleClose} size="xl">
                <Modal.Header closeButton>
                  <Modal.Title>{this.state.iframeData.ExperienceName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Notifications options={{ zIndex: 200, top: '80px' }} />
                  <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <iframe className="iframe-div Shadow" allowFullScreen={true}  allow="vr,gyroscope,accelerometer" src={this.state.iframeData.url} allowfullscreen="yes"  allowvr="yes"></iframe>
                   
                    <div className="row">
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 avatar-div-margin">
                        <h6 className="h6-text-color">{this.state.iframeData.ExperienceName}</h6>
                        <div className="row">
                          <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 ">
                            <img className="imgavatar float-left" alt="" src={(this.state.iframeData.userProfileImagePath && this.state.iframeData.userProfileImagePath != '-') ? this.state.iframeData.userProfileImagePath : Avatar} />
                            <h6 className="paragraph-for-name pl-4">{this.state.iframeData.userName}</h6>
                          </div>
                          {/* <div className="col-lg-7 col-xl-7 col-md-7 col-sm-7">
                          </div> */}
                        </div>
                      </div>
                      <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                        <div className="row">
                          <div className="col-2">
                            <div className="row">
                              <div className="col-12 text-center margin-top-embed tooltip1" >
                                <button className="btn btn-outline p-0" onClick={this.handleShowShare}>
                                  <i className="fa fa-share-alt share-color" aria-hidden="true"></i>
                                </button>
                                <p className="view-text-count "> Share <span className=" tooltiptext1 ">Share</span></p>
                              </div>
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="row">
                              <div className="col-12 text-center clr-pointer tooltip1">
                                <img className="icon-width" src={_self.state.clapFlag ? clap_golden : clap2} onClick={() => { _self.onLikeClick(_self.state.iframeData.promotedId) }} alt="clap" />
                                <p className="view-text-count ">{_self.state.iframeData.likeCount}<span className=" tooltiptext1 " style={marginLeft}>Claps</span></p>
                              </div>
                            </div>
                          </div>
                          <div className="col-2 tooltip1">
                            <div className="row">
                              <div className="col-12 text-center margin-top-embed">
                                <CopyToClipboard text={this.state.iframeData.embed}>
                                  <button className="btn btn-outline p-0" onClick={this.embedTagClick}>
                                    <img className="icon-width" src={embedIcon} alt=" " /></button>
                                </CopyToClipboard>
                                <p className="view-text-count ">{this.state.iframeData.Embedcount}<span className="tooltiptext1" style={marginLeft}>Embed</span></p>
                              </div>
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="row">
                              <div className="col-12 text-center margin-top-embed tooltip1" style={marginTopView}>
                                <CopyToClipboard text={this.state.iframeData.url}>
                                  <button className="btn btn-outline p-0" onClick={this.urlTagClick}>
                                    <img className="icon-width" src={urlIcon}></img></button>
                                </CopyToClipboard>
                                <p className="view-text-count "> Get URL<span className=" tooltiptext1 ">Get URL</span></p>
                              </div>
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="row">
                              <div className="col-12 text-center tooltip1" style={marginTopView}>
                                <img src={eyeImg} />
                                {/* <i className="fa fa-eye icon-view"></i> */}
                                <p className="view-text-count ">{this.state.iframeData.ViewCount}<span className=" tooltiptext1 ">Views</span></p>
                              </div>
                            </div>
                          </div>
                          <div className="col-2">
                            <div className=" text-center margin-top-view clr-pointer">
                              {this.state.reportFlag ?
                                <i className="fa fa-flag text-danger" aria-hidden="true" onClick={this.onClickReport}></i> :
                                <i className="fa fa-flag-o text-dark" aria-hidden="true" onClick={this.onClickReport}></i>}
                              <div className="font-weight-bold" onClick={this.onClickReport}><h6> Report</h6> </div>
                            </div>
                          </div>
                        </div></div>
                    </div>
                    {this.state.showShare ?
                      <div className="Demo__container">
                        <div className="Demo__some-network">
                          <div className="row">
                            <div className=" col-2 col-lg-6 col-xl-6 col-md-6 col-sm-6  m-0 pl-4">
                            </div>
                            <div className="col-1" style={socialMedia}>
                              <FacebookShareButton
                                url={this.state.iframeData.url}
                                quote={title}
                                className="Demo__some-network__share-button">
                                <FacebookIcon
                                  size={32}
                                  round />
                              </FacebookShareButton>
                            </div>
                            <div className="col-1" style={socialMedia}>
                              <TwitterShareButton
                                url={this.state.iframeData.url}
                                title={title}
                                className="Demo__some-network__share-button">
                                <TwitterIcon
                                  size={32}
                                  round />
                              </TwitterShareButton>
                            </div>
                            <div className="col-1" style={socialMedia}>
                              <WhatsappShareButton
                                url={this.state.iframeData.url}
                                title={title}
                                separator=":: "
                                className="Demo__some-network__share-button">
                                <WhatsappIcon size={32} round />
                              </WhatsappShareButton>
                              <div className="Demo__some-network__share-count">
                                &nbsp;
          </div>
                            </div>
                            <div className="col-1" style={socialMedia}>
                              <LinkedinShareButton
                                url={this.state.iframeData.url}
                                windowWidth={750}
                                windowHeight={600}
                                className="Demo__some-network__share-button">
                                <LinkedinIcon
                                  size={32}
                                  round />
                              </LinkedinShareButton>
                            </div>
                            <div className="col-1">
                              <EmailShareButton
                                url={this.state.iframeData.url}
                                subject={title}
                                body=""
                                className="Demo__some-network__share-button">
                                <EmailIcon
                                  size={32}
                                  round />
                              </EmailShareButton>
                            </div>
                          </div>
                        </div>
                      </div>
                      : null}
                    {this.state.showReport ?
                      <Form onSubmit={this.submitReport}>
                        <hr></hr>
                        <h5>Report Experience</h5>
                        <Form.Group controlId="validationCustomUsername" className="row pl-3">
                          {reportOptions.map(function (data, index) {
                            return (
                              <Form.Check
                                id={index}
                                type="radio"
                                label={data}
                                name="formHorizontalRadios"
                                value={data}
                                onChange={_self.handleOptionChange}
                                custom="true"
                                defaultChecked={index === 0}
                                className="col-6"
                              />
                            )
                          })
                          }
                          <InputGroup className="col-12 pl-0 " >{this.otherTypeReport ?
                            <textarea
                              className="form-control mt-3"
                              maxlength="1000"
                              id="exampleFormControlTextarea1"
                              rows="2" required
                              placeholder="Describe the reason for reporting this Experience"
                              value={this.state.reportData}
                              onChange={this.reportChange}
                            />
                            : null}
                          </InputGroup>
                        </Form.Group>
                        <div className="row">
                          <div className="col-12 ">
                            <Button className="btn btn-info Shadow1 float-right" type="submit" > SEND </Button>
                          </div>
                        </div>
                      </Form>
                      : null}
                  </div>
                </Modal.Body>
              </Modal>
              : null}
          </>
        </div>
      </div>
    )
  }
}
export default LinkPage;
