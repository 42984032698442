
import apiservice from '../../Services/apiservice';
import Avatar from '../../assests/images/avatar.png';
import { Button, Modal } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import LastComponent from '../LastComponent/LastComponent';
import { Form, InputGroup, Image } from 'react-bootstrap';
import experizerBlackLogo from '../../assests/images/experizerme2.png';
import Video from '../../assests/images/videoEditingOutput.mp4';
//import {ToastsContainer, ToastsStore} from 'react-toasts';
import Notifications, { notify } from 'react-notify-toast';
import Logo from '../../assests/images/experizerme5.png';
import urlIcon from '../../assests/images/link_96.png';
import embedIcon from '../../assests/images/embed_96.png';
import footerImage from '../../assests/images/HeaderPopup.png';
import clap_golden from '../../assests/images/clap2_golden_96.png'
import clap2 from '../../assests/images/clap2_96.png'
import eyeImg from '../../assests/images/eye.png';
import tick from '../../assests/images/tick_bullet.png';
import experizer from '../../assests/images/experizerlogo_white_100.png';
import React, { Component, useRef, useEffect } from 'react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  EmailIcon,
} from 'react-share';
const title = 'Check out this Experizer room';
const reportOptions = ["Sexual content", "Violent or repulsive content", "Hateful or abusive content", "Harmful dangerous acts", "Promotes terrorism", "Child abuse", "Spam or misleading", "Infringes my rights", "Other"];
class FirstComponent extends React.Component {
  otherExperiences = [];
  Avatar;
  otherTypeReport = false;
  view = false;
  embed = false;
  baseurl;
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      showURL: false,
      showShare: false,
      reportShow: false,
      experienceType: "EDITORS CHOICE",
      iframeData: '',
      otherExperiences: '',
      view: false,
      loading: true,
      reportData: "Sexual content",
      reportFlag: false,
      clapFlag: false,
    };
    this.api = new apiservice();
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.api = new apiservice();
    this.baseurl = 'https://studio.experizer.com';
  }
  componentWillMount() {
    this.getEditorData();
    var name = document.getElementById('iframe');
    var name = this.refs.iframe;
    console.log("name", name);
  }
  componentDidUpdate() {
    console.log("playback", this.PlaybackRate);
    this.PlaybackRate = 0.2;
  }
  getEditorData = () => {
    const url = "/experience/getEditorsChoice";
    this.api.getData(url).then(result => {
      this.data = result.data.data;
      this.viewExperience();
      setTimeout(() => {
        this.setState({ view: true, loading: false });
      }, 4000);
    }).catch((error) => {
      console.log(error);
    });
  }
  handleClose() {
    var sendData = {
      "promotedId": this.iframeData.promotedId
    }
    const url = "/experience/EmbedCount";
    this.api.putData(url, sendData).then(result => {
      notify.show('Embed Copied!', 'success', 4000);
      this.iframeData.Embedcount += 1;
      this.forceUpdate();
    }).catch((error) => {
      console.log(error);
    });
    this.setState({ show: false, showURL: false });
  }
  handleCloseURL = () => {
    notify.show('Link Copied!', 'success', 4000);
    this.setState({ show: false, showURL: false });
  }
  handleCloseShare = () => {
    this.setState({ showShare: false });
  }
  handleShow() {
    this.setState({ show: true });
  }
  handleShowUrl = () => {
    this.setState({ showURL: true });
  }
  handleShowShare = () => {
    this.setState({ showShare: true });
  }
  handleReportShow = () => {
    this.setState({ reportShow: true });
  }
  viewExperience() {
    var j = 0;
    for (var i = 0; i < this.data.length; i++) {
      if (i == 0) {
        var experienceName = (this.data[i].ExperienceName).split(' ').join('-');
        this.data[i].url = this.baseurl + '/act/' + this.data[i].ExperienceFolderPath + '/?' + this.data[i].publishMapId + '--0--0--0/' + experienceName;
        this.data[i].embed = '<iframe src="' + this.data[i].url + '" allowFullScreen={true} frameborder="0" scrolling="no" allow="vr,gyroscope,accelerometer" allowfullscreen="yes"  allowvr="yes"></iframe>';
        this.iframeData = this.data[i];
      }
      else {
        this.otherExperiences[j] = this.data[i];
        this.getExperienceViewCount(j);
        j++;
      }
    }
  }
  onClickCard = (experience) => {
    const url = "/experience/reportedExp/" + experience;
    this.api.getData(url).then(result => {
      var res = result.data.status;
      this.setState({ reportFlag: res });
    }).catch((error) => {
      console.log(error);
    });
    this.setState({ clapFlag: false, loading: false });
    var j = 0;
    this.otherExperiences = [];
    for (var i = 0; i < this.data.length; i++) {
      if (experience == this.data[i].ExperienceId) {
        const pid = this.data[i].promotedId;
        const url1 = "/experience/getExpDataByRoomMappingId/" + pid;
        this.api.getData(url1).then(result => {
          var res = result.data.data[0];
          var experienceName = (res.ExperienceName).split(' ').join('-');
          res.experienceName = experienceName;
          this.iframeData = res;
          this.iframeData.url = this.baseurl + '/act/' + this.iframeData.ExperienceFolderPath + '/?' + this.iframeData.publishMapId + '--0--0--0/' + experienceName;
          this.iframeData.embed = '<iframe src="' + this.iframeData.url + '" allowFullScreen={true} frameborder="0" scrolling="no" allow="vr,gyroscope,accelerometer" allowfullscreen="yes"  allowvr="yes"></iframe>';
          this.setState({ loading: false });
          this.forceUpdate();
        }).catch((error) => {
          console.log(error);
        });
      }
      else {
        this.otherExperiences[j] = this.data[i];
        this.getExperienceViewCount(j);
        j++;
      }
    }
    this.forceUpdate();
  }
  onClick = (experienceType, experienceId, data) => {
    this.data = data;
    this.setState({ experienceType: experienceType });
    this.onClickCard(experienceId);
  }
  closePopUp = () => {
    this.setState({ show: false, reportShow: false, showURL: false });
    this.otherTypeReport = false;
  }
  onLikeClick = (id) => {
    this.iframeData.likeCount += 1;
    var sendData = {
      "promotedId": id
    }
    const url = "/experience/likeCountUpdate";
    this.api.putData(url, sendData).then(result => {
      this.setState({ clapFlag: true });
    }).catch((error) => {
      console.log(error);
    });
    this.forceUpdate();
  }
  handleChange = (e) => {
    this.setState({ reportData: e.target.value });
  }
  handleSubmit = (e) => {
    e.preventDefault();
    var expId = this.iframeData.ExperienceId;
    var expName = this.iframeData.ExperienceName;
    var sendData = {
      "reportText": this.state.reportData,
      "experienceId": expId,
      "experienceName": expName,
    }
    const url = "/experience/report";
    this.api.postData(url, sendData).then(result => {
      if (result.data.status == true) {
        notify.show('Experience Reported', 'success', 3000);
        this.setState({ reportFlag: true });
        this.otherTypeReport = false;
      }
    }).catch((error) => {
      console.log(error);
    });
    this.setState({ show: false, reportShow: false, selectedOption: null, reportData: "Sexual content" });
  }
  handleOptionChange = (e) => {
    if (e.target.value == "Other") {
      this.otherTypeReport = true;
      this.setState({ reportData: e.target.null });
    }
    else {
      this.otherTypeReport = false;
      this.setState({ reportData: e.target.value });
    }
  }
  gotoExperience() {
    window.scrollTo(800,800);
    document.body.scrollTop = 800;
    document.documentElement.scrollTop = 800;//scroll to top of the page
  }
  onKeyPressed(e) {
    console.log(e.keyCode);
  }
  getExperienceViewCount(id) {
    var publishMapId = this.otherExperiences[id].publishMapId;
    const url2 = "/experience/getViewCountByExperience/" + publishMapId;
    this.api.getData(url2).then(result => {
      var res = result.data.data[0];
      this.otherExperiences[id].ViewCount = (res.ViewCount);
      console.log("Result", res);
    }).catch((error) => {
      console.log(error);
    });
  }
  render() {
    var _self = this;
    var shown = {
      display: this.state.loading ? "block" : "none"
    };
    var marginTopView = {
      marginTop: '-2px'
    };
    var playTooltip = {
      marginTop: '80px'
    };
    var socialMedia = {
      paddingRight: '27px'
    };
    return (
      <div className="FirstComponent" >
        <header className="v-header">
          <div className="fullscreen-video-wrap">
            <video id="myVideo" src={Video} playbackRate="0.5" autoPlay="autoplay" loop={true}>
            </video>
          </div>
          <div className="header-overlay">
            <div className="row">
              <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1">
                <img className="main-logo" src={Logo} />
              </div>
            </div>
            <div className="header-content mt-0 mt-sm-0 mt-md-2 mt-lg-3 mt-xl-5 ml-2 ml-2 pt-lg-5 pt-xl-5">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 margin-top-header">
                <h2 className="font-weight-style"><span className="free-style1"> Community Contributed </span>Immersive Assessments, </h2>
                <h2 className="font-weight-style"> Virtual Tours & Microlearning Experiences</h2>
              </div>
              <div className="col-9">
                <div className="row font-weight-style1">
                  <div className="col-12  pt-3 pt-sm-0">
                    <span className="tick-icon float-left">
                      <img src={tick} />
                    </span>
                    <h4 className="para">
                      Choose and integrate with your Content or Website freely
                </h4>
                  </div>
                  <div className="col-12">
                    <span className="tick-icon float-left">
                      <img src={tick} />
                    </span>
                    <h4 className="para">
                      Create or Modify these rooms by{" "}
                      <a
                        href="https://studio.experizer.com/#/login"
                        className="a-color"
                        target="_blank"
                      >
                        Registering on{" "}
                        <span>
                          <img className="width-experizer" src={experizer} />
                        </span>
                      </a>
                    </h4>
                  </div>
                  <div className="col-12">
                    <span className="tick-icon float-left">
                      <img src={tick} />
                    </span>
                    <h4 className="para">
                      Track/Analyze the viewer activity with Experizer{" "}
                    </h4>
                  </div>
                  <div className="col-12">
                    <span className="tick-icon float-left">
                      <img src={tick} />
                    </span>
                    <h4 className="para">
                      Integrate with your &nbsp;
                  <a
                        className="a-color"
                        href="https://www.youtube.com/watch?v=Mva3x4ZGH6I"
                        target="_blank"
                      >
                        Articulate Rise,
                  </a>&nbsp;
                  <a
                        className="a-color"
                        href="https://www.youtube.com/watch?v=Mva3x4ZGH6I"
                        target="_blank"
                      >
                        Articulate 360
                  </a>
                      &nbsp;   OR Captivate
                </h4>
                  </div>
                  <div className="col-12">
                    <span className="tick-icon float-left">
                      <img src={tick} />
                    </span>
                    <h4 className="para">
                      Use these on Desktops, Mobiles or almost all VR Glasses
                </h4>
                  </div>
                  <div className="col-12">
                    <span className="tick-icon float-left">
                      <img src={tick} />
                    </span>
                    <h4 className="para">
                      Check out the secret of creating these without
                      programming - in minutes
                </h4>
                  </div>
                </div>
              </div>
              <div className="container-fluid mt-2 mt-sm-0">
                <button onClick={this.gotoExperience} className="btn btn-info1  btn btn-success  padding-left-right">
                  view Experiences
                  </button>
                <a href="https://studio.experizer.com/#/login" target="_blank">
                  <button type="submit" className="btn btn-info1  btn btn-success  padding-left-right">
                    Join Experizer
                  </button>
                </a>
              </div>
            </div>
          </div>
        </header>
        <div className="main-div pl-4 pr-4 sticky-top">
          <div className="row">
            <div className="col-2">
              <img
                className="logoimg"
                src={experizerBlackLogo}
                alt="experizer_logo"
              />
            </div>
            <div className="col-10 text-right">
              <a href="https://studio.experizer.com/#/login" target="_blank">
                <button type="submit" className="btn btn-info  btn btn-primary  padding-left-right">
                  Try Experizer
                </button>
              </a>
            </div></div>
        </div>
        <div className="loader" style={shown} />
        <div>
          <Notifications options={{ zIndex: 200, top: '100px' }} />
          {this.state.view ?
            <section>
              <div className="container-fluid section-border-bottom pl-4 pr-4 pt-3">
               
                <div className="h5-div-style">
                  <h4>{this.state.experienceType}</h4>  </div>
                <div className="row">
                  <div className="col-12 col-xl-8 col-lg-8 col-md-12 col-sm-12 mb-3 pl-4">
                  
                    <iframe id="iframeDiv" className="iframe-div" src={this.iframeData.url} allowFullScreen={true} frameBorder="0" scrolling="no" allow="vr,gyroscope,accelerometer" allowfullscreen="yes"  allowvr="yes"></iframe>
                  
                    <div className="row">
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 avatar-div-margin">
                        <h6 className="h6-text-color">{this.iframeData.ExperienceName}</h6>
                        <div className="row">
                          <div className="col-1 ">
                            <img className="imgavatar" alt="" src={this.iframeData.userProfileImagePath ? this.iframeData.userProfileImagePath : Avatar} />
                          </div>
                          <div className="col-7">
                            <h6 className="paragraph-for-name ">{this.iframeData.userName}</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 pr-4">
                        <div className="row">
                          <div className="col-2">
                            <div className="row">
                              <div className="col-12 text-center margin-top-embed tooltip1" >
                                <button className="btn btn-outline p-0 " onClick={this.handleShowShare}>
                                  <i className="fa fa-share-alt share-color" aria-hidden="true"></i>
                                </button>
                                <p className="view-text-count "> Share <span className=" tooltiptext1 ">Share</span></p>
                              </div>
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="row">
                              <div className="col-12 text-center clr-pointer tooltip1">
                                <img className="icon-width" src={this.state.clapFlag ? clap_golden : clap2} onClick={() => { _self.onLikeClick(this.iframeData.promotedId) }} alt="clap" />
                                <p className="view-text-count  ">{this.iframeData.likeCount}<span className=" tooltiptext1 ">Claps</span></p>
                              </div>
                              <div className="col-1">
                              </div>
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="row">
                              <div className="col-12 text-center margin-top-embed tooltip1" style={marginTopView}>
                                <button className="btn btn-outline p-0" onClick={this.handleShow}>
                                  <img className="icon-width" src={embedIcon}></img> </button>
                                {/* <i className="fa fa-eye icon-view"></i> */}
                                <p className="view-text-count ">{this.iframeData.Embedcount}<span className=" tooltiptext1 ">Embed</span></p>
                              </div>
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="row">
                              <div className="col-12 text-center margin-top-embed tooltip1" style={marginTopView}>
                                <button className="btn btn-outline p-0" onClick={this.handleShowUrl}>
                                  <img className="icon-width" src={urlIcon}></img>
                                </button>
                                <p className="view-text-count "> Get URL<span className=" tooltiptext1 ">Get URL</span></p>
                              </div>
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="row">
                              <div className="col-12 text-center margin-top-view tooltip1" style={marginTopView}>
                                <img src={eyeImg} />
                                <p className="view-text-count ">{this.iframeData.ViewCount}<span className=" tooltiptext1 ">Views</span></p>
                              </div>
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="row">
                              <div className="col-12 text-center margin-top-view text-dark clr-pointer" style={marginTopView}>
                                {this.state.reportFlag ?
                                  <i className="fa fa-flag text-danger" aria-hidden="true" onClick={this.handleReportShow}></i> :
                                  <i className="fa fa-flag-o text-dark" aria-hidden="true" onClick={this.handleReportShow}></i>}
                                <div className=" clr-default"> <b>Report</b></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <>
                        <Modal show={this.state.show} onHide={this.closePopUp} size="lg">
                          <Modal.Header closeButton className="pt-2 pb-2">
                            <Modal.Title>Embed</Modal.Title>
                          </Modal.Header>
                          <Modal.Body><textbox value={this.iframeData.embed} oncopy="return false;" onpaste="return false;" oncut="return false;" >{this.iframeData.embed}</textbox>
                            <div className="row">
                              <div className="col-12 ">
                                <CopyToClipboard text={this.iframeData.embed}>
                                  <Button className="btn btn-info Shadow1 float-right" onClick={this.handleClose}>Copy </Button>
                                </CopyToClipboard>
                              </div>
                            </div>
                          </Modal.Body>
                          <Image src={footerImage} />
                        </Modal>
                      </>
                      <>
                        <Modal show={this.state.showShare} onHide={this.handleCloseShare} >
                          <Modal.Header closeButton className="pt-2 pb-2">
                            <Modal.Title>Share Experience</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <div className="Demo__container">
                              <div className="Demo__some-network">
                                <div className="row">
                                  <div className="col-2 col-lg-3 col-xl-3 col-sm-3">
                                  </div>
                                  <div className="col-1" style={socialMedia}>
                                    <FacebookShareButton
                                      url={this.iframeData.url}
                                      quote={title}
                                      className="Demo__some-network__share-button">
                                      <FacebookIcon
                                        size={32}
                                        round />
                                    </FacebookShareButton>
                                  </div>
                                  <div className="col-1" style={socialMedia}>
                                    <TwitterShareButton
                                      url={this.iframeData.url}
                                      title={title}
                                      className="Demo__some-network__share-button">
                                      <TwitterIcon
                                        size={32}
                                        round />
                                    </TwitterShareButton>
                                  </div>
                                  <div className="col-1" style={socialMedia}>
                                    <WhatsappShareButton
                                      url={this.iframeData.url}
                                      title={title}
                                      separator=":: "
                                      className="Demo__some-network__share-button">
                                      <WhatsappIcon size={32} round />
                                    </WhatsappShareButton>
                                    <div className="Demo__some-network__share-count">
                                      &nbsp;
          </div>
                                  </div>
                                  <div className="col-1" style={socialMedia}>
                                    <LinkedinShareButton
                                      url={this.iframeData.url}
                                      windowWidth={750}
                                      windowHeight={600}
                                      className="Demo__some-network__share-button">
                                      <LinkedinIcon
                                        size={32}
                                        round />
                                    </LinkedinShareButton>
                                  </div>
                                  <div className="col-1">
                                    <EmailShareButton
                                      url={this.iframeData.url}
                                      subject={title}
                                      body=""
                                      className="Demo__some-network__share-button">
                                      <EmailIcon
                                        size={32}
                                        round />
                                    </EmailShareButton>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Modal.Body>
                          <Image src={footerImage} />
                        </Modal>
                      </>
                      <>
                        <Modal show={this.state.showURL} onHide={this.closePopUp} size="lg">
                          <Modal.Header closeButton className="pt-2 pb-2">
                            <Modal.Title>Copy URL</Modal.Title>
                          </Modal.Header>
                          <Modal.Body><textbox value={this.iframeData.url} oncopy="return false;" onpaste="return false;" oncut="return false;" >{this.iframeData.url}</textbox>
                            <div className="row">
                              <div className="col-12 ">
                                <CopyToClipboard text={this.iframeData.url}>
                                  <Button className="btn btn-info Shadow1 float-right" onClick={this.handleCloseURL}>Copy </Button>
                                </CopyToClipboard>
                              </div>
                            </div>
                          </Modal.Body>
                          <Image src={footerImage} />
                        </Modal>
                      </>
                      <>
                        <Modal show={this.state.reportShow} onHide={this.closePopUp} size="lg">
                          <Modal.Header closeButton className="pt-2 pb-2">
                            <Modal.Title>Report Experience </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Form onSubmit={this.handleSubmit}>
                              <Form.Group controlId="validationCustomUsername" className="row pl-3">
                                {reportOptions.map(function (data, index) {
                                  return (
                                    <Form.Check
                                      id={index}
                                      key={index}
                                      type="radio"
                                      label={data}
                                      name="formHorizontalRadios"
                                      value={data}
                                      onChange={_self.handleOptionChange}
                                      custom="true"
                                      defaultChecked={index === 0}
                                      className="col-6"
                                    />
                                  )
                                })}
                                <InputGroup className="col-12 pl-0 " >{this.otherTypeReport ?
                                  <textarea
                                    className="form-control mt-3"
                                    id="exampleFormControlTextarea1"
                                    rows="2" required
                                    maxlength="1000"
                                    placeholder="Describe the reason for reporting this Experience"
                                    value={this.state.reportData}
                                    onChange={this.handleChange}
                                  />
                                  : null}
                                </InputGroup>
                              </Form.Group>
                              <div className="row">
                                <div className="col-12 ">
                                  <Button className="btn btn-info Shadow1 float-right " type="submit" > SEND </Button>
                                </div>
                              </div>
                            </Form>
                          </Modal.Body>
                          <Image src={footerImage} />
                        </Modal>
                      </>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 textcenter">
                    {this.otherExperiences.slice(0, 4).map(function (data, index) {
                      return (
                        <div className="row" key={index} >
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 clr-pointer" onClick={() => { _self.onClickCard(data.ExperienceId) }}>
                            <div className="container-img1 border-to-cards">
                              <img className="card-img-top-last1" src={data.ThumbnailPath} />
                              <div className="overlay">
                                <div className="text2">{data.ExperienceName}</div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <h6 className="experience-name">{data.ExperienceName}</h6>
                            <h6 className="author-name">{data.userName}</h6>
                            <p className="views">{data.ViewCount}<span className="span-views">Views</span></p>
                          </div>
                        </div>);
                    })}
                  </div>
                </div>
              </div>
            </section>
            : null}
          <LastComponent onClick={this.onClick} />
        </div>
      </div>
    );
  }
}
export default FirstComponent;