import axios from 'axios';
import { callbackify } from 'util';

export default class apiservice {

   baseUrl = "https://api.experizer.com";
   //baseUrl="http://me.experizer.com:8768";
   //   baseUrl="http://10.0.0.73:3000";

   getUrl() {
      return this.baseUrl;
   }

   getData(url) {
      return axios.get(this.baseUrl + url);
   } 
   putData(url, data) {
      return axios.put(this.baseUrl + url, data);
   }

   postData(url, data) {
      return axios.post(this.baseUrl + url, data);
   }


} 