import React from 'react';
import './App.css';
import FirstComponent from './Components/FirstComponent/FirstComponent';
import createHistory from 'history/createBrowserHistory';
export const history = createHistory();

function App() {


  return (
    <div className="App" id="container-check">
      <FirstComponent  />
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <button class="btn btn-success">button</button>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
    </div>
  );
}

export default App;
